import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RenewalFootnote,
  crossSaleText,
  crossSaleSelectBoxText,
} from 'Members/utils/purchase_helper';

const CrossSaleSelection = ({
  useVersion = 0,
  crossSale,
  crossSaleValue,
  onChange,
  isSubmitting,
}) => {
  /* Dont show xsale if we have no info! */
  if (crossSale === null) {
    return null;
  }

  const { id: crossSaleId } = crossSale;

  const isSelected = (crossSaleValue || '').toString() === crossSaleId.toString();

  const onChecked = e => {
    onChange({
      target: {
        name: 'crossSaleId',
        value: e.target.checked ? crossSaleId.toString() : null,
      },
    });
  };

  const onSelectChanged = e => {
    onChange({
      target: {
        name: 'crossSaleId',
        value: e.target.value.length === 0 ? null : e.target.value,
      },
    });
  };

  return (
    <Fragment>
      {useVersion === 0 /* show as check boxes */ && (
        <div className="row">
          <div className="flex-wrap col">
            <label className="d-flex align-items-center" style={{ margin: '15px 0' }}>
              <input
                type="checkbox"
                name="crossSale"
                className="form-check-input"
                value={crossSaleId.toString()}
                checked={isSelected}
                onChange={onChecked}
                disabled={isSubmitting}
                style={{ display: 'none' }}
              />

              <FontAwesomeIcon
                icon={['fal', isSelected ? 'check-square' : 'square']}
                style={{ fontSize: 20, marginRight: '15px' }}
              />

              <span className="auth-form-notice text-left" style={{ margin: 0 }}>
                {crossSaleText(crossSale)}
              </span>
            </label>
          </div>
        </div>
      )}

      {useVersion === 1 /* Show select drop down */ && (
        <select
          className="form-control"
          defaultValue={crossSaleId.toString()}
          onChange={onSelectChanged}
        >
          <option value={crossSaleId.toString()}>{crossSaleSelectBoxText(crossSale)}</option>
          <option value="">{`No thanks, I'm not interested in this special offer.`}</option>
        </select>
      )}
    </Fragment>
  );
};

CrossSaleSelection.propTypes = {
  crossSale: PropTypes.object,
  crossSaleValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  useVersion: PropTypes.number.isRequired,
};

CrossSaleSelection.defaultProps = {
  useVersion: 0,
};

const CrossSaleFootnote = ({ crossSaleValue, crossSale }) => {
  if (crossSale === null || crossSaleValue === 'false') {
    return null;
  }

  return <span className="auth-form-notice">** {RenewalFootnote(crossSale)}</span>;
};

CrossSaleFootnote.propTypes = {
  crossSale: PropTypes.object,
  crossSaleValue: PropTypes.string,
};

export { CrossSaleFootnote, CrossSaleSelection };
