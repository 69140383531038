import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { useAppState, useForm } from 'Members/hooks';
import { submitPasswordResetForm } from 'Members/actions';
import { Routes } from 'Members/const';
import InlineAlert from 'Members/components/InlineAlert';
import { SubmitButton, Input, validatePasswordResetForm } from './Form';

const ResetPassword = ({ loginRoute, signupRoute }) => {
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const id = params.id || query.get('id');
  const token = params.token || query.get('token');

  const [, dispatch] = useAppState();
  const { values, edits, errors, isSubmitting, formAlert, isValid, onChange, onSubmit } = useForm(
    submitPasswordResetForm.bind(this, dispatch),
    validatePasswordResetForm,
    { c: id, reset_password_token: token }
  );

  if (formAlert && formAlert.type === 'success') {
    return <Redirect to={loginRoute} />;
  }

  return (
    <form className="auth-forgot-password-form auth-form" onSubmit={onSubmit} noValidate>
      <h1>
        Reset
        <br />
        Password
      </h1>

      {formAlert && <InlineAlert icon={{ icon: ['fal', 'exclamation-triangle'] }} {...formAlert} />}

      <fieldset>
        <Input
          type="password"
          name="password"
          icon={['fal', 'lock-alt']}
          placeholder="New Password"
          value={values.password || ''}
          error={edits.password && errors.password}
          onChange={onChange}
        />

        <Input
          type="password"
          name="password_confirmation"
          icon={['fal', 'lock-alt']}
          placeholder="Confirm"
          value={values.password_confirmation || ''}
          error={edits.password_confirmation && errors.password_confirmation}
          onChange={onChange}
        />
      </fieldset>

      <SubmitButton
        text="Reset Password"
        disabled={() => isSubmitting || !isValid}
        isSubmitting={isSubmitting}
      />

      <Link to={signupRoute} className="auth-form-alt-link mt-2">
        {`Don't have an account? Join now`}
      </Link>

      <Link to={loginRoute} className="auth-form-alt-link mt-2">
        Member Login
      </Link>
    </form>
  );
};

ResetPassword.defaultProps = {
  loginRoute: Routes.login(),
  signupRoute: Routes.signup(),
};

ResetPassword.propTypes = {
  loginRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  signupRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ResetPassword;
