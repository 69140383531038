import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Form';

const PaymentForm = ({ errors, values, edits, onChange, isSubmitting }) => {
  return (
    <Fragment>
      <Input
        type="text"
        name="name"
        icon={['fal', 'user']}
        placeholder="Cardholder's Name"
        autocomplete="cc-name"
        value={values.name || ''}
        error={edits.name && errors.name}
        onChange={onChange}
        disabled={isSubmitting}
      />

      <Input
        type="text"
        name="cardNumber"
        icon={['fal', 'credit-card']}
        placeholder="Card Number"
        autocomplete="cc-number"
        value={values.cardNumber || ''}
        error={edits.cardNumber && errors.cardNumber}
        onChange={onChange}
        disabled={isSubmitting}
      />

      <div className="row">
        <Input
          type="text"
          name="expDate"
          className="col"
          placeholder="MM/YY"
          autocomplete="cc-exp"
          value={values.expDate || ''}
          error={edits.expDate && errors.expDate}
          onChange={onChange}
          disabled={isSubmitting}
        />

        <Input
          type="text"
          name="cvv"
          className="col"
          placeholder="CVV"
          autocomplete="cc-csc"
          value={values.cvv || ''}
          error={edits.cvv && errors.cvv}
          onChange={onChange}
          disabled={isSubmitting}
        />

        <Input
          type="text"
          name="zipCode"
          className="col"
          placeholder="ZIP"
          autocomplete="postal-code"
          value={values.zipCode || ''}
          error={edits.zipCode && errors.zipCode}
          onChange={onChange}
          disabled={isSubmitting}
        />
      </div>
    </Fragment>
  );
};

PaymentForm.propTypes = {
  values: PropTypes.object.isRequired,
  edits: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PaymentForm;
