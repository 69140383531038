import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage, SocialMediaIcon } from 'Members/components/shared';
import { RequireUserType, RequireProductAccess } from 'Members/utils';
import { Messages } from 'Members/const';
import { monthlyPackage } from 'Members/utils/purchase_helper';

const CtaJumbotron = ({
  className,
  ctaClassName,
  productId,
  billingGroup,
  onCtaButtonClick,
  children,
}) => {
  const containerCss = classNames('girl-details-jumbotron', className);
  const ctaCss = classNames('girl-details-jumbotron-cta', ctaClassName);
  const oneClickPackage = monthlyPackage(billingGroup);
  const priceText = oneClickPackage ? ` for ${oneClickPackage.initialAmount}` : '';

  return (
    <div className={containerCss}>
      {children}

      <RequireProductAccess productId={productId} free>
        <div className={ctaCss}>
          <RequireUserType premium>
            <h5>{`Add my private feed of revealing videos and galleries${priceText}!`}</h5>
            <button className="btn btn-upgrade border-light" onClick={onCtaButtonClick}>
              Unlock My Updates &gt;&gt;
            </button>
          </RequireUserType>

          <RequireUserType guest freemium>
            <h5>{Messages.girlPurchaseCta}</h5>
            <button className="btn btn-upgrade border-light" onClick={onCtaButtonClick}>
              Join Now &gt;&gt;
            </button>
          </RequireUserType>
        </div>
      </RequireProductAccess>
    </div>
  );
};

const GirlDetails = ({
  productId,
  name,
  biography,
  dateOfBirth,
  measurements,
  urls,
  headerUrl,
  billingGroup,
  onCtaButtonClick,
}) => (
  <section className="girl-details-section container-fluid px-0">
    <div className="girl-details-hero text-center">
      <LazyImage src={headerUrl} />

      <CtaJumbotron
        ctaClassName="w-50 m-auto d-none d-md-block"
        productId={productId}
        name={name}
        billingGroup={billingGroup}
        onCtaButtonClick={onCtaButtonClick}
      >
        <h1 className="d-none d-md-block">{name}</h1>
      </CtaJumbotron>
    </div>
    <div className="row girl-details-description text-center px-2 mt-2 mx-auto">
      <CtaJumbotron
        className="col-12 my-3 d-md-none"
        productId={productId}
        name={name}
        billingGroup={billingGroup}
        onCtaButtonClick={onCtaButtonClick}
      >
        <h1 className="d-md-none">{name}</h1>
      </CtaJumbotron>

      {dateOfBirth && (
        <div className="col-12 col-md-3 offset-md-1 my-3 girl-details-fact ">
          <h3>Date of Birth</h3>
          <span>{dateOfBirth}</span>
        </div>
      )}
      {measurements && (
        <div className="col-12 col-md-4 my-3 girl-details-fact">
          <h3>Measurements</h3>
          <span>{measurements}</span>
        </div>
      )}
      {urls && (
        <div className="col-12 col-md-3 my-3 girl-details-fact">
          <h3>Follow Her</h3>
          <span>
            <SocialMediaIcon name="facebook" url={urls.facebook} />
            <SocialMediaIcon name="twitter" url={urls.twitter} />
            <SocialMediaIcon name="instagram" url={urls.instagram} />
            <SocialMediaIcon name="website" url={urls.website} />
          </span>
        </div>
      )}
    </div>
    <div className="row girl-details-biography text-center text-muted px-2 mx-auto">
      <p className="col-12 col-md-8 offset-md-2" dangerouslySetInnerHTML={{ __html: biography }} />
    </div>
  </section>
);

CtaJumbotron.propTypes = {
  className: PropTypes.string,
  ctaClassName: PropTypes.string,
  productId: PropTypes.number,
  billingGroup: PropTypes.object,
  onCtaButtonClick: PropTypes.func.isRequired,
};

GirlDetails.propTypes = {
  productId: PropTypes.number,
  name: PropTypes.string.isRequired,
  biography: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  measurements: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  headerUrl: PropTypes.string.isRequired,
  billingGroup: PropTypes.object,
  onCtaButtonClick: PropTypes.func.isRequired,
};

export default GirlDetails;
