import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppLink } from 'Members/utils';
import { useAppState, useForm } from 'Members/hooks';
import { submitSignupForm } from 'Members/actions';
import { Routes } from 'Members/const';
import { Input, validateSignupForm } from './Form';

const Signup = ({ loginRoute, subTitle, submitAction, className }) => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, isValid, onChange, onSubmit } = useForm(
    submitAction.bind(this, dispatch),
    validateSignupForm,
    { emailable: true }
  );

  const formCss = classNames('auth-signup-form auth-form', className);

  return (
    <form className={formCss} onSubmit={onSubmit} noValidate>
      <h1>Sign Up</h1>
      {subTitle && <h6>{subTitle}</h6>}

      <fieldset>
        <Input
          type="text"
          name="username"
          icon={['fal', 'user']}
          placeholder="Username"
          autocomplete="username"
          value={values.username || ''}
          error={edits.username && errors.username}
          onChange={onChange}
        />

        <Input
          type="password"
          name="password"
          icon={['fal', 'lock-alt']}
          placeholder="Password"
          autocomplete="new-password"
          value={values.password || ''}
          error={edits.password && errors.password}
          onChange={onChange}
        />

        <Input
          type="email"
          name="email"
          icon={['fal', 'envelope']}
          placeholder="Email"
          autocomplete="email"
          value={values.email || ''}
          error={edits.email && errors.email}
          onChange={onChange}
        />
      </fieldset>

      <span className="auth-form-notice">
        By signing up you certify that you are 18 years or older and&nbsp;accept&nbsp;our&nbsp;
        <AppLink to={Routes.privacy()}>privacy&nbsp;policy</AppLink>.
      </span>

      <button type="submit" className="auth-form-submit btn btn-accent btn-lg" disabled={!isValid}>
        Continue
      </button>

      <AppLink to={loginRoute} className="auth-form-alt-link" noscroll>
        Already have an account? Log in
      </AppLink>
    </form>
  );
};

Signup.defaultProps = {
  loginRoute: Routes.login(),
  submitAction: submitSignupForm,
};

Signup.propTypes = {
  loginRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.string,
  submitAction: PropTypes.func,
  className: PropTypes.string,
};

export default Signup;
