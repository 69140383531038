import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import SiteConfig from 'site_config';
import { Routes } from 'Members/const';
import { ProtectedRoute } from 'Members/utils';
import { useAppState } from 'Members/hooks';
import { Login, Signup, Payment, ResetPassword, ForgotPassword } from 'Members/components/Auth';
import { FullPageVideo } from 'Members/components/layout';
import WelcomeForm from './welcome_form';

const TourLogin = () => {
  const [{ restrictedApp }] = useAppState();
  const title = restrictedApp ? 'Premium Member Login' : 'Login';
  const signupRoute = restrictedApp ? null : Routes.tourSignup();
  const supportRoute = restrictedApp ? SiteConfig.company.supportUrl : null;

  return (
    <Login
      title={title}
      signupRoute={signupRoute}
      supportRoute={supportRoute}
      forgotPasswordRoute={Routes.tourForgotPassword()}
    />
  );
};

const TourSignup = () => <Signup loginRoute={Routes.tourLogin()} />;

const TourResetPassword = () => (
  <ResetPassword loginRoute={Routes.tourLogin()} signupRoute={Routes.tourSignup()} />
);

const VideoTour = ({ location }) => {
  const [{ restrictedApp }] = useAppState();
  const videoUrl = restrictedApp ? null : SiteConfig.videoTour.videoUrl;

  return (
    <FullPageVideo
      videoUrl={videoUrl}
      logoRoute={Routes.tour()}
      location={location}
      className="video-tour-page"
    >
      <Switch>
        {/* Redirect auth routes to the tour auth routes */}
        <Redirect from={Routes.login()} to={Routes.tourLogin()} />
        <Redirect from={Routes.signup()} to={Routes.tourSignup()} />
        <Redirect from={Routes.signupPayment()} to={Routes.tourSignupPayment()} />
        <Redirect
          from={Routes.forgotPasswordWithToken()}
          to={Routes.tourForgotPasswordWithToken()}
        />
        <Redirect from={Routes.forgotPassword()} to={Routes.tourForgotPassword()} />

        <ProtectedRoute path={Routes.tour()} component={WelcomeForm} exact guest />
        <ProtectedRoute path={Routes.tourLogin()} component={TourLogin} exact guest />
        <ProtectedRoute path={Routes.tourSignup()} component={TourSignup} exact guest />
        <ProtectedRoute path={Routes.tourSignupPayment()} component={Payment} exact freemium />
        <ProtectedRoute
          path={Routes.tourForgotPasswordWithToken()}
          component={TourResetPassword}
          exact
          guest
        />
        <ProtectedRoute path={Routes.tourForgotPassword()} component={ForgotPassword} exact guest />
      </Switch>
    </FullPageVideo>
  );
};

VideoTour.propTypes = {
  location: PropTypes.object,
};

export default VideoTour;
