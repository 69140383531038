import React from 'react';
import { AppLink } from 'Members/utils';
import { useAppState, useForm } from 'Members/hooks';
import { submitSignupForm } from 'Members/actions';
import { Routes } from 'Members/const';
import { Input, validateSignupForm } from './Form';

const InlineSignup = () => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, isValid, onChange, onSubmit } = useForm(
    submitSignupForm.bind(this, dispatch),
    validateSignupForm,
    { emailable: true }
  );

  return (
    <form className="auth-form inline-auth-form" onSubmit={onSubmit} noValidate>
      <div className="form-inline">
        <div className="title mb-2 mr-sm-2">
          <h1>Sign Up To Get Access</h1>
        </div>

        <Input
          type="text"
          name="username"
          className="mb-2 mr-sm-2"
          placeholder="Username"
          autocomplete="username"
          value={values.username || ''}
          error={edits.username && errors.username}
          onChange={onChange}
        />

        <Input
          type="password"
          name="password"
          className="mb-2 mr-sm-2"
          placeholder="Password"
          autocomplete="new-password"
          value={values.password || ''}
          error={edits.password && errors.password}
          onChange={onChange}
        />

        <Input
          type="email"
          name="email"
          className="mb-2 mr-sm-2"
          placeholder="Email"
          autocomplete="email"
          value={values.email || ''}
          error={edits.email && errors.email}
          onChange={onChange}
        />

        <div className="input-lg input-group flex-wrap mb-2 mr-sm-2">
          <button
            type="submit"
            className="auth-form-submit btn btn-accent mb-2"
            disabled={!isValid}
          >
            Continue
          </button>
        </div>
      </div>

      <span className="auth-form-notice">
        By signing up you certify that you are 18 years or older and&nbsp;accept&nbsp;our&nbsp;
        <AppLink to={Routes.privacy()}>privacy&nbsp;policy</AppLink>.
      </span>
    </form>
  );
};

export default InlineSignup;
