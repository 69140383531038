import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Routes } from 'Members/const';
import { AppLink } from 'Members/utils';
import { useAppState, useForm } from 'Members/hooks';

import { RecaptchaNotice } from 'Members/components/shared';

import {
  clickLogoutLink,
  getJoinFormData,
  submitPaymentForm,
  trackPurchaseView,
} from 'Members/actions';

import {
  MembershipType,
  InitialTerm,
  InitialAmount,
  RenewalFootnote,
  BundledPackageFootnote,
} from 'Members/utils/purchase_helper';

import InlineAlert from 'Members/components/InlineAlert';
import SiteConfig from 'site_config';
import { SubmitButton, validatePaymentForm } from './Form';
import { PaymentForm, CrossSaleSelection, CrossSaleFootnote, PackageSelection } from './JoinForm';

/*
  If we have an active a/b test, activate this
*/
const EXPECTING_SPLIT_TEST = false;

/** ************************ */
/* Render the payment form */
/** ************************ */
const Payment = ({
  history,
  subTitle,
  freeProductId,
  getBillingInfoAction,
  trackViewAction,
  submitAction,
}) => {
  const [{ user, errors: formSubmissionErrors, splitTest = {} }, dispatch] = useAppState();
  const { name: splitTestName } = splitTest; /* use experiment versions */

  const [joinFormData, setJoinFormData] = useState({});

  const { values, edits, errors, setValues, isSubmitting, isValid, onChange, onSubmit } = useForm(
    submitAction.bind(this, dispatch),
    validatePaymentForm
  );

  /* Send XHR Request to get billing package information and build the form */
  useEffect(() => {
    getBillingInfoAction({}, setJoinFormData.bind(this));
  }, []);

  /* When form data is ready, we get the html built and decide what we should be doing. */
  useEffect(() => {
    const { id: billingGroupId, billingPackages, crossSale } = joinFormData || {};
    const crossSaleId = crossSale && crossSale.id; // "precheck" the xsale
    const crossSaleName = crossSale && crossSale.name; // "precheck" the xsale
    const packageId = billingPackages && billingPackages[0].id; // pre-select the first option
    const purchaseFormName = splitTestName || undefined;

    setValues({
      crossSaleId,
      billingGroupId,
      packageId,
      billingPackages,
      purchaseFormName,
      crossSaleName,
      freeProductId,
    });

    /*
      We track the page view if...
      - No a/b test is active & group has been set
      - A/b test is active with version + group has been set
    */
    if (
      trackViewAction &&
      ((EXPECTING_SPLIT_TEST && purchaseFormName && billingGroupId) ||
        (!EXPECTING_SPLIT_TEST && billingGroupId))
    ) {
      trackViewAction({ id: billingGroupId, purchaseFormName });
    }
  }, [joinFormData, splitTestName]);

  const { error: formError, id: billingGroupId, billingPackages } = joinFormData;
  const { packageId: selectedPackageId } = values;

  const multiplePackages = billingPackages && billingPackages.length > 1;
  const selectedPackage =
    (billingPackages && billingPackages.find(bp => bp.id === selectedPackageId)) || {};

  // We have an initializing problem. App crashed before we could set up
  if (formError) {
    return (
      <div className="auth-payment-form auth-form">
        <InlineAlert
          icon={{ icon: ['fal', 'exclamation-triangle'] }}
          type="danger"
          message={formError}
        />
      </div>
    );
  }

  // We're loading the initial package data
  if (billingGroupId === undefined || typeof selectedPackage === 'undefined') {
    return (
      <div className="auth-payment-form auth-form">
        <FontAwesomeIcon icon={['fal', 'spinner-third']} spin style={{ fontSize: 45 }} />
      </div>
    );
  }

  const onPackageSelectionChange = billingPackage => {
    setValues({ ...values, packageId: billingPackage.id });
  };

  const onLogoutClick = event => {
    event.preventDefault();

    (async () => {
      await clickLogoutLink(dispatch);
      history.replace('/');
    })();
  };

  const formClassNames = classNames({
    'mb-4': true,
    'auth-payment-form': true,
    'auth-form': true,
    'mt-1': multiplePackages,
    'auth-form-lg': multiplePackages,
  });

  const subTitleClassNames = classNames({
    'mt-1': multiplePackages,
    'mt-3': !multiplePackages,
  });

  return (
    <div>
      <PackageSelection
        billingPackages={billingPackages}
        selectedPackage={selectedPackage}
        onChange={onPackageSelectionChange}
        isSubmitting={isSubmitting}
      />

      <form className={formClassNames} onSubmit={onSubmit} noValidate>
        {multiplePackages === false && (
          <Fragment>
            <h2 className="single-heading">
              <span>
                {SiteConfig.displayName}
                <br />
                {MembershipType(selectedPackage)}
              </span>
              <span>
                {InitialAmount(selectedPackage)} - {InitialTerm(selectedPackage)}*
              </span>
            </h2>
          </Fragment>
        )}

        {subTitle && <h6 className={subTitleClassNames}>{subTitle}</h6>}

        {formSubmissionErrors && (
          <InlineAlert
            icon={{ icon: ['fal', 'exclamation-triangle'] }}
            type="danger"
            message={formSubmissionErrors}
          />
        )}

        <fieldset>
          <PaymentForm
            values={values}
            edits={edits}
            errors={errors}
            onChange={onChange}
            isSubmitting={isSubmitting}
          />
          <CrossSaleSelection
            crossSale={joinFormData.crossSale}
            crossSaleValue={values.crossSaleId}
            onChange={onChange}
            isSubmitting={isSubmitting}
          />
        </fieldset>

        <SubmitButton
          text="Join"
          disabled={() => isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          loadingInstruction="do not refresh browser"
        />

        <span className="auth-form-notice">
          By completing this transaction you agree to&nbsp;the&nbsp;
          <AppLink to={Routes.terms()}>terms&nbsp;of&nbsp;service</AppLink>.
        </span>

        <span className="auth-form-notice">* {RenewalFootnote(selectedPackage)}</span>

        <CrossSaleFootnote crossSale={joinFormData.crossSale} crossSaleValue={values.crossSaleId} />

        <span className="auth-form-notice">
          <BundledPackageFootnote {...selectedPackage} />
        </span>

        <div className="pt-5">
          Logged in as {user.email}. Not you?{' '}
          <a href="" onClick={onLogoutClick}>
            Log out.
          </a>
        </div>

        <RecaptchaNotice />
      </form>
    </div>
  );
};

Payment.defaultProps = {
  getBillingInfoAction: getJoinFormData,
  trackViewAction: trackPurchaseView,
  submitAction: submitPaymentForm,
};

Payment.propTypes = {
  history: PropTypes.object,
  subTitle: PropTypes.string,
  freeProductId: PropTypes.number,
  getBillingInfoAction: PropTypes.func,
  trackViewAction: PropTypes.func,
  submitAction: PropTypes.func,
};

export default Payment;
