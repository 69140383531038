import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  bundledPackageText,
  InitialAmount,
  InitialTerm,
  RebillsDefined,
} from 'Members/utils/purchase_helper';

const emptyFn = () => {};

const PackageSelectBox = ({
  id,
  initialAmount,
  initialDays,
  rebillDays,
  trial,
  lifetime,
  introductory,
  isSelected,
  onChange,
  partnerPackages,
}) => {
  const onPackageClicked = e => {
    e.preventDefault();

    onChange({ id });
  };

  const packageClassNames = classNames({
    package: true,
    'package-selected': isSelected,
  });

  return (
    <a className={packageClassNames} onClick={onPackageClicked}>
      <span className="package-initial-amount">{InitialAmount({ initialAmount })}</span>
      <span className="package-initial-term">
        {InitialTerm({ trial, introductory, lifetime, initialDays })}
        {isSelected ? '*' : ''}
      </span>
      <span className="package-rebill-type">
        {RebillsDefined({ trial, lifetime, introductory, rebillDays })}
      </span>

      {partnerPackages && partnerPackages.length > 0 && (
        <span className="package-initial-term">{bundledPackageText(partnerPackages)}</span>
      )}

      {isSelected && (
        <span className="text-center mt-4">
          <span className="d-inline px-4 py-2 bg-base text-base-contrast text-uppercase rounded-pill small">
            Selected
          </span>
        </span>
      )}
    </a>
  );
};

PackageSelectBox.propTypes = {
  id: PropTypes.number.isRequired,
  initialAmount: PropTypes.string,
  initialDays: PropTypes.number,
  rebillDays: PropTypes.number,
  partnerPackages: PropTypes.array,
  trial: PropTypes.bool.isRequired,
  lifetime: PropTypes.bool.isRequired,
  introductory: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PackageSelection = ({
  billingPackages,
  selectedPackage,
  onChange,
  isSubmitting,
  children,
}) => {
  if (billingPackages.length === 1) {
    return null;
  }

  return (
    <form className="mb-1 auth-payment-form auth-form auth-form-lg" noValidate>
      <h3 className="align-items-center text-uppercase">Membership:</h3>
      <div
        className={`d-flex flex-row justify-content-center packages packages-ln-${billingPackages.length}`}
      >
        {billingPackages.map(bp => (
          <PackageSelectBox
            key={`billing-package-${bp.id}`}
            {...bp}
            isSelected={selectedPackage.id === bp.id}
            onChange={isSubmitting ? emptyFn : onChange}
          />
        ))}
      </div>
      {children}
    </form>
  );
};

PackageSelection.propTypes = {
  billingPackages: PropTypes.array.isRequired,
  selectedPackage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

export default PackageSelection;
